<template>
  <div class="searchReview">
    <div class="searchReview__form">
      <div class="iconReview">
        <i class="fas fa-search iconReview__i" @click="showlistUsers"></i>
        <input
          class="iconReview__input"
          type="search"
          name="search"
          v-model="searchValue"
          placeholder="Buscar Usuario"
          @keyup="autoSearch"
          @click="showlistUsers"
          autocomplete="off"
        />
      </div>
      <div class="searchReview__results" v-if="search && search.length">
        <div class="searchReview__item" @click="filterData(user.user)" v-for="(user, midx) in search" :key="midx">
          <div>{{ user.user }}</div>
          <div class="searchReview__role">{{ user.role }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search: null,
      searchValue: "",
    };
  },
  computed: {
    ...mapGetters("users", { getUsers: "getData" }),
  },
  methods: {
    //reinicia la data para ocultar la lista
    initListUser() {
      this.search = [];
    },
    //funcion reinicia el input del buscador
    resetSearch() {
      this.searchValue = "";
    },
    //inicializa la data para mostrar al iniciar
    showlistUsers() {
      this.search = this.getUsers.filter((user) => user.role === "model" || user.role === "monitor").sort((a, b) => (a.user > b.user ? 1 : -1));
    },
    //emite evento con el nombre de usuario como query a NotifyList
    async filterData(user) {
      this.$root.$emit("sendUserQueryReview", user);
      this.searchValue = user;
      this.initListUser();
    },
    //devuelve array con filtro con regex de lo escrito en el input
    filterUserName(data) {
      this.initListUser();
      return this.getUsers
        .filter((user) => (user.role === "model" || user.role === "monitor") && user.user.match(new RegExp("^" + data, "i")))
        .sort((a, b) => (a.user > b.user ? 1 : -1));
    },
    //inicializa la data deacuerdo al filtro obtenido
    autoSearch: async function() {
      if (event.srcElement.value) {
        this.search = this.filterUserName(event.srcElement.value);
      } else {
        this.showlistUsers();
      }
    },
  },
};
</script>

<style lang="scss">
.searchReview {
  &__item {
    padding: 10px;
    font-size: 16px;
    text-align: left;
    text-transform: capitalize;
    color: $mine_shaft;
    border-bottom: 1px solid $porcelain;
    &:hover {
      background: $porcelain;
    }
  }
  &__role {
    font-size: 12px;
    color: $lipstick;
  }
  &__results {
    width: auto;
    min-width: 250px;
    max-height: 270px;
    position: absolute;
    z-index: 52;
    margin-top: 20px;
    margin-left: -10px;
    overflow-x: auto;
    border-radius: 5px;
    background: $white;
  }
  .iconReview {
    position: relative;
    &__i {
      top: 5px;
      left: 9px;
      position: absolute;
      color: $lipstick;
    }
    &__input {
      max-width: 160px;
      padding-left: $mpadding * 2;
      border-radius: 5px;
      border: 1px solid $lipstick;
      outline: none;
      text-align: left;
      text-overflow: ellipsis;
    }
  }
}
@media (min-width: $tablet_width) {
  .searchUsers {
    .iconInput {
      position: relative;
      &__i {
        left: 3px;
      }
      &__input {
        padding-left: $mpadding * 1.5;
      }
    }
  }
}
</style>
