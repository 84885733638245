<template>
  <div class="modalPhotos">
    <div class="modalPhotos__wrapper">
      <div class="modalPhotos__container" v-if="photo">
        <div class="modalPhotos__relative">
          <div>
            <img class="modalPhotos__img" :src="photo" alt="img" />
            <p class="modalPhotos__description">{{ description }}</p>
          </div>
          <button class="modalPhotos__icon" @click="clickCancel"><i class="fas fa-times"></i></button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { photo: { type: String, default: "" }, description: { type: String, default: "" } },
  methods: {
    clickCancel() {
      this.$emit("onCancelphoto");
    },
  },
};
</script>

<style lang="scss">
.modalPhotos {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  background-color: rgba(19, 5, 5, 0.876);

  &__description {
    width: 90%;
    margin: 0 auto;
    color: $white;
  }
  &__img {
    display: block;
    width: 90%;
    height: auto;
    margin: 0 auto;
    object-fit: contain;
  }
  &__wrapper {
    @include Row();
    width: 100%;
    height: 100%;
    position: relative;
  }
  &__container {
    @include Column();
    margin-top: 50px;
    max-width: auto;
    height: 90vh;
    background-color: transparent;
  }
  &__relative {
    position: relative;
  }
  &__icon {
    position: absolute;
    color: $white;
    font-size: 25px;
    top: -30px;
    right: 20px;
    background-color: transparent;
    border: none;
    &:focus {
      outline: none;
    }
  }
}
@media (min-width: $mobile_width) {
  .modalPhotos {
    &__img {
      height: 75vh;
      object-fit: contain;
    }
  }
}
</style>
