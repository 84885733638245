<template>
  <div class="modalMaintenance" :key="key">
    <div class="modalMaintenance__title">Estado mantenimiento</div>
    <div class="modalMaintenance__change">
      <div class="modalMaintenance__label" v-html="isMantenience ? 'Requerido' : 'No requerido'"></div>
      <toggleInput @isChecked="getModel" :checked="data.actualState" />
    </div>
    <div class="modalMaintenance__label">Comentario:</div>
    <div
      class="modalMaintenance__textarea"
      :id="`text-${data.dateDanger}-${data.savedDate}-${data.userStatusChange}`"
      contenteditable="true"
      nobars
    ></div>
    <div class="modalMaintenance__btns">
      <button class="modalMaintenance__btn" @click="sendReviewMaintenence">Actualizar</button>
      <button class="modalMaintenance__btn modalMaintenance__btn--cancel" @click="closeModalMaintenence">Cancelar</button>
    </div>
  </div>
</template>

<script>
import toggleInput from "@/components/input/ToggleInput.vue";
import axios from "@/api/axios";
import swal from "@/js/swalAlerts";
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    toggleInput,
  },
  data() {
    return {
      key: 1,
      isMantenience: this.data.actualState,
      statusMantenience: "",
    };
  },
  methods: {
    getModel(model) {
      this.isMantenience = model;
    },
    closeModalMaintenence() {
      this.$emit("closeModalMaintenence");
    },
    sendReviewMaintenence() {
      const _data = new FormData();
      const textReview = document.getElementById(`text-${this.data.dateDanger}-${this.data.savedDate}-${this.data.userStatusChange}`).innerHTML;
      _data.append("dateDamage", this.data.dateDanger);
      _data.append("sede", this.data.sede);
      _data.append("monitorUser", this.data.monitorName);
      _data.append("dateRepair", this.data.savedDate);
      _data.append("changeStateUser", this.data.userStatusChange);

      _data.append("room", this.data.room);
      _data.append("city", this.data.city);
      _data.append("statusMaintenence", this.isMantenience);
      _data.append("comentMaintenence", textReview);
      _data.append("typeNotify", "modal");

      swal
        .dataSendConfirm({
          message: "Se actualizara el estado de mantenimiento de la habitación",
          title: "",
        })
        .then(async (result) => {
          if (result.isConfirmed) {
            swal.dataSending({ message: " ", title: "Actualizando" });
            let res = await axios.put(`/maintenence`, _data);
            if (res.data.success) {
              swal.dataSendSuccess({ message: res.data.success, title: "" });
              this.$emit("closeModalMaintenence");
              this.$root.$emit("updateDataReviews", this.isMantenience, this.data.room);
            } else {
              swal.dataSendError({ message: res.data.notSuccess, title: "" });
            }
          }
        });
    },
  },
};
</script>

<style lang="scss">
.modalMaintenance {
  .toggle-switch {
    background: $japanese_laurel;
  }
  position: absolute;
  padding: $mpadding;
  align-items: center;
  justify-content: center;
  width: 250px;
  min-height: 210px;
  z-index: 100;
  background: $white;
  border-radius: $border-radius-bootstrap;
  box-shadow: 0px 3px 5px #929292, -0 -2px 2px #ffffff;
  &__title {
    margin-bottom: 20px;
    font-size: 16px;
    text-align: center;
    font-weight: bold;
    font-family: $first_font;
    color: $black;
  }
  &__change {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  &__label {
    color: $black;
  }
  &__textarea {
    padding: 2px 5px;
    min-height: 50px;
    max-height: 100px;
    overflow-y: auto;
    margin-bottom: 10px;
    border: 1px solid $alto;
    border-radius: 5px;
    &:focus {
      outline: none;
    }
  }
  &__btns {
    display: flex;
    justify-content: space-around;
  }
  &__btn {
    @include ButtonStyle();
    width: 100px;
    background: $lipstick;
    padding: $mpadding/2 $mpadding;
    &--cancel {
      background: $black;
    }
  }
}
</style>
