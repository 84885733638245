<template>
  <div class="adminReview" v-if="review && review.photosReview">
    <div :class="['adminReview__card', showForm ? 'backgroundCard' : 'backgroundNoActive']">
      <div class="adminReview__group">
        <div class="adminReview__date">{{ dateForm(review.date) }}</div>
        <button class="adminReview__seeForm" @click="showFormReview" v-if="getRole === 'superadmin' || getRole === 'coordinator'">
          <i class="fas fa-eye"></i>
          Ver formulario
        </button>
      </div>
      <div class="adminReview__data">
        <div class="adminReview__upData">
          <div class="adminReview__monitorName" v-html="`<strong>Monitor:</strong> ${review.userNameMonitor}`"></div>
          <div class="adminReview__modelName" v-html="`<strong>Modelo:</strong> ${review.userNameModel}`"></div>
          <div class="adminReview__city" v-html="`<strong>Ciudad:</strong> ${review.city}`"></div>
        </div>
        <div class="adminReview__leftData">
          <div class="adminReview__room" v-html="`<strong>Habitación:</strong> ${review.room}`"></div>
          <div class="adminReview__sede" v-html="`<strong>Sede:</strong> ${review.office}`"></div>
        </div>
        <div class="adminReview__rigthData">
          <div class="adminReview__stateRoom">
            Estado de la habitación:
            <i class="fas fa-check-circle success" v-if="review.statusRoom == 'success'"></i>
            <i class="fas fa-times-circle danger" v-else></i>
          </div>
          <div
            :class="['adminReview__stateRoom', 'cursor']"
            @click="modalMaintenence"
            v-if="review.statusMantenienceRoom && (getRole === 'superadmin' || (getRole === 'coordinator' && getSpecialFunctions !== false))"
          >
            <span :class="['adminReview__stateRoom--admins']">
              <i class="fas fa-toolbox"></i>
              Mantenimiento:
            </span>
            <i class="fas fa-tools danger" v-if="review.statusMantenienceRoom"></i>
            <i class="fas fa-check-circle success" v-else></i>
          </div>
          <div v-else :class="['adminReview__stateRoom']">
            <span>Mantenimiento:</span>
            <i class="fas fa-tools danger" v-if="review.statusMantenienceRoom"></i>
            <i class="fas fa-check-circle success" v-else></i>
          </div>
          <modalMaintenance v-if="showModalMaintenence" @closeModalMaintenence="closeModalMaintenence" :data="dataModal" />
        </div>
        <div class="adminReview__botton">
          <label class="adminReview__labels">Observaciones:</label>
          <div class="adminReview__observations" v-html="review.observation"></div>
        </div>
      </div>
      <div class="adminReview__allPhotos">
        <div :class="['adminReview__mantenencePhotos adminReview__fieldSet', showForm ? 'adminReview__fieldSet--active' : '']" tittle="Revisión Habitaciones" v-if="review.photosReview.length">
          <img v-for="(photo, idx) in review.photosReview" :key="idx" class="adminReview__img" :src="photo" :alt="`photo${idx}`" @click="sendDataModal(photo, idx, 'review')" />
        </div>
        <div :class="['adminReview__mantenencePhotos adminReview__fieldSet', showForm ? 'adminReview__fieldSet--active' : '']" tittle="Mantenimiento" v-if="review.photosReviewMantenience.length">
          <img v-for="(photo, idx) in review.photosReviewMantenience" :key="idx" class="adminReview__img" :src="photo" :alt="`mantenence${idx}`" @click="sendDataModal(photo, idx, 'maintenance')" />
        </div>
      </div>
    </div>
    <div class="adminReview__cardForm" v-if="showForm">
      <reviewsForm :data="reviewData" :bools="booleanFields" />
    </div>
    <div v-if="showModal">
      <ModalReview @onCancelphoto="cancelModal" :photo="photo" :description="photoDescription" />
    </div>
  </div>
</template>

<script>
  import reviewsForm from "@/components/widgets/Reviews.vue";
  import { dateLocaleFormat } from "@/js/utils";
  import ModalReview from "@/components/modals/modalPhotosReview.vue";
  import modalMaintenance from "@/views/main/reviewsAdmin/modalMaintenance.vue";
  import { mapGetters } from "vuex";

  export default {
    props: {
      review: {
        type: Object,
        default: () => ({}),
      },
    },
    data() {
      return {
        photo: null,
        photoDescription: null,
        showModal: false,
        reviewData: null,
        booleanFields: null,
        showForm: false,
        showModalMaintenence: false,
        dataModal: {},
        optionFormat: {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        },
      };
    },
    components: {
      reviewsForm,
      ModalReview,
      modalMaintenance,
    },
    computed: {
      ...mapGetters("user", { getRole: "getRole", getProfileToken: "getProfileToken", getSpecialFunctions: "getSpecialFunctions" }),
    },
    methods: {
      closeModalMaintenence() {
        this.showModalMaintenence = false;
      },
      modalMaintenence() {
        this.showModalMaintenence = !this.showModalMaintenence;
        this.dataModal = {
          dateDanger: this.review.date,
          sede: this.review.office,
          room: this.review.room,
          city: this.review.city,
          monitorName: this.review.userNameMonitor,
          savedDate: new Date(),
          userStatusChange: this.getProfileToken.user,
          actualState: this.review.statusMantenienceRoom,
          id: this.review._id,
        };
      },
      sendDataModal(photo, idx, type) {
        this.photo = photo;
        if (type === "maintenance") {
          this.photoDescription = this.review.descriptionMantenience[idx];
        }
        if (type === "review") {
          this.photoDescription = this.review.descriptionPhotos[idx];
        }
        this.showModal = true;
      },
      cancelModal() {
        this.showModal = false;
      },
      dateForm(date) {
        return dateLocaleFormat(date, this.optionFormat);
      },
      async showFormReview() {
        this.showForm = !this.showForm;
        await this.objectPropForm();
      },
      objectPropForm() {
        this.reviewData = {
          typeForm: "isEdit",
          id: this.review._id,
          date: this.review.date,
          city: this.review.city,
          room: this.review.room,
          sede: this.review.office,
          turn: this.review.editDate,
          observations: this.review.observation,
          photosReview: this.review.photosReview,
          userNameModel: this.review.userNameModel,
          userNameMonitor: this.review.userNameMonitor,
          descriptionPhotos: this.review.descriptionPhotos,
          turnOfTransmition: this.review.turnOfTransmition,
          statusMantenienceRoom: this.review.statusMantenienceRoom,
          isEnable: this.review.isEnable,
          descriptionMantenience: this.review.descriptionMantenience,
          photosReviewMantenience: this.review.photosReviewMantenience,
        };
        this.booleanFields = {
          tv: this.review.tv,
          bed: this.review.bed,
          cpu: this.review.cpu,
          floor: this.review.floor,
          mirror: this.review.mirror,
          shower: this.review.shower,
          tripod: this.review.tripod,
          webcam: this.review.webcam,
          cabling: this.review.cabling,
          nightOwl: this.review.nightOwl,
          softbotx: this.review.softbotx,
          functionalAir: this.review.functionalAir,
          accessoriesPc: this.review.accessoriesPc,
          airConditioner: this.review.airConditioner,
        };
      },
    },
    mounted: async function() {
      this.$root.$on("closeReviewEdit", () => {
        this.showForm = false;
      });
    },
  };
</script>

<style lang="scss">
  .adminReview {
    width: 100%;
    font-size: 14px;

    &__fieldSet {
      width: 100%;
      padding: 20px 15px 5px;
      margin: 10px 0;
      border: 1px solid $primary_color;
      border-radius: 13px;
      position: relative;
      &::before {
        content: attr(tittle);
        position: absolute;
        top: -13px;
        left: 24px;
        z-index: 1;
        color: $primary_color;
        font-family: $sec_font;
        font-size: 18px;
        background-color: $white;
      }
      &--active {
        &::before {
          background-color: rgb(246, 241, 241);
        }
      }
    }
    &__group {
      display: flex;
      justify-content: space-between;
      margin-bottom: 5px;
    }
    &__seeForm {
      border: none;
      margin-top: 5px;
      height: 20px;
      background-color: transparent;
      color: $lipstick;
      font-weight: bold;
      &:hover {
        transform: scale(0.97);
        border-bottom: 1px solid $lipstick;
      }
      &:focus {
        outline: none;
      }
    }
    &__cardForm {
      margin: 0 auto;
      margin-top: 4px;
      max-width: 1440px;
      box-shadow: 0 2px 5px $alto;
      padding: $mpadding $mpadding * 2;
      background-color: $white;
      border-radius: 1em;
    }
    &__card {
      margin: 0 auto;
      margin-top: 10px;
      max-width: 1440px;
      width: 100%;
      border-radius: 10px;
      background-color: $white;
      box-shadow: 0 2px 5px $alto;
      padding: $mpadding/2 $mpadding * 2;
    }

    &__date {
      padding-top: 10px;
      color: $lipstick;
      font-weight: bold;
    }

    &__data {
      display: flex;
      flex-direction: column;
    }
    &__upData,
    &__leftData,
    &__botton,
    &__rigthData {
      flex-direction: column;
      padding: $mpadding/2 0;
    }

    &__labels,
    &__stateRoom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
      &--admins {
        color: $lipstick;
        &:hover {
          border-bottom: 1px solid $lipstick;
        }
      }
    }
    &__observations {
      min-width: 200px;
      height: 50px;
      overflow-y: auto;
      line-height: 110%;
    }
    &__evidences {
      display: block;
    }
    &__mantenencePhotos {
      margin-top: 20px;
      display: block;
    }
    &__img {
      max-width: 100%;
      width: 70px;
      height: 70px;
      border-radius: 8px;
      margin: 0 11px 11px 0;
      &:hover {
        cursor: pointer;
        box-shadow: 3px 3px 3px #5a5a5a, -3px -3px 3px #ffffff;
      }
    }
    .cursor {
      cursor: pointer;
    }
    .success {
      color: $japanese_laurel;
      margin-left: 10px;
    }
    .danger {
      color: $lipstick;
      margin-left: 10px;
    }
    .backgroundCard {
      background-color: $backgroundCards;
    }
    .backgroundNoActive {
      background-color: $white;
    }
  }
  @media (min-width: $tablet_width) {
    .adminReview {
      &__leftData,
      &__rigthData {
        margin-left: 15px;
      }
      &__upData {
        margin-left: 0;
      }
      &__botton {
        max-width: 200px;
        margin-left: 30px;
      }
      &__data {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      &__allPhotos {
        margin-top: 5px;
        display: flex;
        justify-content: space-between;
      }
      &__evidences {
        display: flex;
        width: 45%;
        margin-top: 0;
      }
      &__mantenencePhotos {
        width: 45%;
        margin-top: 0;
      }
    }
  }
</style>
