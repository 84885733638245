<template>
  <div class="adminview" v-bind="showResetFilter()">
    <div class="wrapShow" @click="maskNotifications"></div>
    <div class="adminview__nav">
      <div class="adminview__selects" :key="resetSelect">
        <searchReview class="adminview__Search" ref="search" @click.native="toggleSearch" v-if="getRole === 'superadmin' || getRole === 'coordinator'" />
        <button class="adminview__btn" @click="$refs.myDatePicker.togglePicker()">
          <i class="fas fa-calendar-alt adminview__icons"></i>
          <span v-html="`Fecha`"></span>
        </button>
        <div class="adminview__item" v-if="getRole === 'superadmin' || getRole === 'coordinator'">
          <select class="adminview__select" name="city" @change="setFilter">
            <option value="allcity">Ciudades</option>
            <option v-for="(city, idx) in cities" :key="idx" :value="idx">{{ idx }}</option>
          </select>
        </div>
        <div class="adminview__item" v-if="getRole === 'superadmin' || getRole === 'coordinator'">
          <select class="adminview__select" name="office" @change="setFilter">
            <option value="allsedes">Sedes</option>
            <option v-for="(office, idx) in getOffices" :key="idx" :value="office.officeName">{{ office.officeName }}</option>
          </select>
        </div>
        <div class="adminview__item">
          <select class="adminview__select" name="statusRoom" @change="setFilter">
            <option value="allstate">Revisiones</option>
            <option value="success">Buen Estado</option>
            <option value="notSuccess">Mal Estado</option>
          </select>
        </div>
        <div class="adminview__item" v-if="getRole === 'superadmin' || getRole === 'coordinator'">
          <select class="adminview__select" name="statusMantenienceRoom" @change="setFilter">
            <option value="allMaintenance">Mantenimiento</option>
            <option value="true">Requerido</option>
            <option value="false">No requerido</option>
          </select>
        </div>
        <div v-if="showReset">
          <button class="adminview__btn" @click="setFilter('reset')">
            <i class="adminview__icons fas fa-times"></i>
            <span>Quitar Filtros</span>
          </button>
        </div>
      </div>
    </div>
    <v-md-date-range-picker class="calendarColor" ref="myDatePicker" @change="handleDate" :start-date="this.$route.query.dateFrom" :end-date="this.$route.query.dateTo"></v-md-date-range-picker>
    <div v-bind="getReviews" class="adminview__container">
      <div class="adminview__items" v-if="reviews.length">
        <adminReviewList v-for="(review, idx) in reviews" :key="idx" :review="review" :id="`i${review._id}`" />
      </div>
    </div>
    <div v-if="reviews.length < totalReviews">
      <button class="adminview__more click-on-bottom" @click="showMore((cpage += 1))">ver más</button>
    </div>
    <div class="spinner" v-if="isLoading"></div>
  </div>
</template>

<script>
  import router from "../../../router";
  import axios from "@/api/axios";
  import searchReview from "@/views/main/reviewsAdmin/searchReview.vue";
  import adminReviewList from "@/views/main/reviewsAdmin/adminReviewList.vue";
  import { mapGetters } from "vuex";
  import setfilterMixin from "@/mixins/setfilter";

  export default {
    mixins: [setfilterMixin],
    data() {
      return {
        page: 1,
        cpage: 1,
        reviews: [],
        loading: null,
        offices: null,
        userName: null,
        resetSelect: 1,
        isLoading: false,
        showReset: false,
        totalReviews: null,
        path: "/adminReview/?type=allReview",
        cities: this.$global.dictionary.cities,
      };
    },
    components: {
      adminReviewList,
      searchReview,
    },
    computed: {
      ...mapGetters("offices", { getOffices: "getData" }),
      ...mapGetters("user", { getRole: "getRole" }),
      async getReviews(page) {
        if (this.$route.name === "adminReview") {
          page = page >= 1 ? page : 1;
          let _host = this.getRequest(page);
          const objReviews = await axios.get(_host);
          this.reviews = objReviews.data.allReviews;
          this.totalReviews = objReviews.data.totalReviews;
        }
      },
    },
    methods: {
      showResetFilter() {
        let currentQuery = this.$route.fullPath.substring(1);
        currentQuery !== "adminReview/?type=allReview" ? (this.showReset = true) : (this.showReset = false);
      },
      maskNotifications() {
        this.$refs.search.initListUser();
        this.toggleSearch();
      },
      toggleSearch() {
        const wrap = document.querySelector(".wrapShow");
        wrap.classList.toggle("wrapShow-active");
        if (!wrap.classList.contains("wrapShow-active")) {
          this.$refs.search.initListUser();
        }
      },
      async showMore(cpage) {
        this.isLoading = true;
        this.page = cpage;
        await this.getPage(this.page);
        this.isLoading = false;
      },
      async getPage(cpage) {
        this.isLoading = true;
        this.page = cpage;
        let _host = this.getRequest(this.page);
        const objReviews = await axios.get(_host);
        this.reviews.push(...objReviews.data.allReviews);
        this.totalReviews = objReviews.data.totalReviews;
        this.isLoading = false;
      },
      handleDate: function(data, values) {
        this.setFilter({ srcElement: { name: "dateFrom", value: values[0] } });
        this.setFilter({ srcElement: { name: "dateTo", value: values[1] } });
      },
      getRequest: function(page) {
        let currentQuery = this.$route.fullPath.substring(1);
        if (currentQuery && currentQuery.includes("#")) {
          currentQuery = currentQuery.split("#")[0];
        }
        let _host = `/reviews/${currentQuery}`;
        return _host.includes("?") ? `${_host}&page=${page}` : `${_host}/?type=allReview&page=${page}`;
      },
    },
    beforeMount() {
      this.showResetFilter();
    },
    async mounted() {
      this.setFilter("reset");
      this.$root.$off("sendUserQueryReview");
      this.$root.$on("sendUserQueryReview", async (model) => {
        this.userName = model;
        this.setFilter({ srcElement: { name: "user", value: model } });
      });

      this.$root.$off("updateReviewRefresh");
      this.$root.$on("updateReviewRefresh", async (data) => {
        const userIndex = this.reviews.findIndex((i) => i._id === data._id);
        if (userIndex > -1) {
          this.reviews.splice(userIndex, 1, data);
        }
      });
      this.$root.$off("closeReviewEdit");
      this.$root.$on("closeReviewEdit", (id) => {
        this.$global.scrollToEl({ el: `#i${id}`, topgap: 60 });
      });
      this.$root.$off("updateDataReviews");
      this.$root.$on("updateDataReviews", async (value, room) => {
        const users = this.reviews.filter((i) => i.room === room);
        if (users.length) {
          this.reviews = this.reviews.map((i) => {
            if (i.room === room) {
              i.statusMantenienceRoom = value;
            }
            return i;
          });
        }
      });
    },
  };
</script>

<style lang="scss">
  .adminview {
    width: 100%;
    .wrapShow {
      display: none;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: fixed;
      z-index: 50;
      background: rgba(0, 0, 0, 0.1);
      &-active {
        display: block;
      }
    }
    &__Search {
      margin-left: 15px;
      z-index: 52;
    }
    &__nav {
      display: flex;
      min-height: 40px;
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
      margin-top: 32px;
      border-radius: 10px;
      font-family: 14px;
      font-size: $label-forms;
      background: $white;
      box-shadow: 0 2px 5px $alto;
      align-items: center;
    }
    &__container {
      width: 100%;
    }
    &__more {
      border: none;
      background-color: transparent;
      color: $lipstick;
      opacity: 0;
    }
    &__item {
      margin: 0 10px;
    }
    &__btn {
      border: none;
      color: $lipstick;
      font-weight: bold;
      background: transparent;
      white-space: nowrap;
      margin: 0 10px;
      &:focus {
        outline: none;
      }
    }
    &__icons {
      margin: 0 3px 0 10px;
    }
    &__selects {
      display: flex;
      justify-content: space-around;
      width: 100%;
      overflow-x: auto;
    }
    &__select {
      width: auto;
      border: none;
      color: $lipstick;
      font-weight: bold;
      &:focus {
        outline: none;
      }
      &:hover {
        cursor: pointer;
      }
    }
    .mdrp__activator {
      display: none;
    }
    .mdrp__panel.dropdown-menu[data-v-7863e830] {
      margin-top: -45px;
      margin-left: 50px;
    }
    .mdrp__panel .calendar-table.right[data-v-7863e830] {
      display: none;
    }
    .calendarColor {
      color: $black;
      font-family: $first_font;
    }
    table.calendar-table td.calendar-cell.active .calendar-cell__content[data-v-89ba891e],
    table.calendar-table td.calendar-cell.active:hover .calendar-cell__content[data-v-89ba891e] {
      background-color: $lipstick;
    }
    .ranges ul[data-v-4e185066] .active {
      color: $lipstick;
    }
  }
</style>
